import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { NgMaterialMultilevelMenuModule, MultilevelMenuService } from 'ng-material-multilevel-menu';


@NgModule({
    imports: [ RouterModule, CommonModule, NgMaterialMultilevelMenuModule ],
    declarations: [ SidebarComponent ],
    exports: [ SidebarComponent ],
    providers: [MultilevelMenuService]
})

export class SidebarModule {
    
}
