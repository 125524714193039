import { Component, OnInit } from '@angular/core';
import { MultilevelNodes } from "ng-material-multilevel-menu";

export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: '' },
    { path: '/staff', title: 'Staff', icon: 'nc-single-02', class: '' },
    { path: '/vehicles', title: 'Vehicles', icon: 'nc-bus-front-12', class: '' },
    { path: '/zone', title: 'Zone', icon: 'nc-bus-front-12', class: '' },
    { path: '/postal', title: 'Postal', icon: 'nc-bus-front-12', class: '' },
    { path: '/order', title: 'Order', icon: 'nc-box-2', class: '' },
    { path: '/scheduling', title: 'Scheduling', icon: 'nc-watch-time', class: '' },
    { path: '/scheduling-per-day', title: 'Scheduling Per Day', icon: 'nc-watch-time', class: '' },
    { path: '/assigne-order', title: 'Assigned Order', icon: 'nc-bus-front-12', class: '' },
    { path: '/maps', title: 'Maps', icon: 'nc-map-big', class: '' },
    { path: '/reports', title: 'Order', icon: 'nc-single-copy-04', class: '' },
    { path: '/special-zone', title: 'Special Zone', icon: 'nc-bus-front-12', class: '' },
    { path: '/special-postal', title: 'Special Postal', icon: 'nc-bus-front-12', class: '' },
];

@Component({
    moduleId: module.id,
    selector: 'sidebar4-cmp',
    templateUrl: 'sidebar4.component.html',
})

export class Sidebar4Component implements OnInit {

    appitems: MultilevelNodes[] = [
        // { link: '/dashboard/2023', label: 'Dashboard', icon: 'dashboard' },





        
        
        { link: '/order-partner/1/1', label: 'Global Search', icon: 'manage_search' },

                { link: '/order-partner', label: 'Orders Option 1', icon: 'toc' },

               

            //   //  { link: '/order/1', label: 'Search Furniture', icon: 'manage_search' },
                
            //     { link: '/maps', label: 'Maps Furniture', icon: 'map' },

            //     { link: '/assigne-order', label: 'Assigned Orders', icon: 'event_seat' },

                { link: '/special-order-partner', label: 'Orders Option 2', icon: 'toc' },

            //   //  { link: '/special-order/1', label: 'Search Option 2', icon: 'manage_search' },

            //     { link: '/special-maps', label: 'Maps Option 2', icon: 'map' },

            //     { link: '/manual-order', label: 'Manual Orders', icon: 'toc' },

            //     { link: '/capacityplan', label: 'Capacity Plan', icon: 'departure_board' },

            { link: '/parked-order-partner', label: 'Parked Orders', icon: 'toc' },

            { link: '/reports/samples-report', label: 'Samples Report', icon: 'library_books' },

        // {
        //     label: "Order Management", icon: "contacts", items: [
                
        //         { link: '/manual-order', label: 'Manual Orders', icon: 'toc' },


        //         { link: '/order', label: 'Orders', icon: 'toc' },

                

        //         { link: '/order/1', label: 'Search', icon: 'manage_search' },
                
        //         { link: '/maps', label: 'Maps', icon: 'map' },

        //         { link: '/special-order', label: 'Special Orders', icon: 'toc' },

        //         { link: '/special-maps', label: 'Special Maps', icon: 'map' },

        //         { link: '/assigne-order', label: 'Assigned Orders', icon: 'event_seat' },
        //         //{ link: '/unassign-order', label: 'Unassigned Orders', icon: 'event_seat' },
        //     ]
        // },
        // { link: '/staff', label: 'Staff', icon: 'contacts' },
        // {
        //     label: "Vehicle Management", icon: "directions_bus", items: [
        //         { link: '/vehicles', label: 'Vehicles', icon: 'directions_bus' },
        //         { link: '/vehicle-scheduling', label: 'Vehicle Scheduling', icon: 'departure_board' },
        //         { link: '/scheduling-per-day', label: 'Scheduling By Date', icon: 'departure_board' },
        //     ]
        // },

        // {
        //     label: 'Set-up',
        //     icon: "settings_applications",
        //     items: [
        //         { link: '/staff', label: 'Staff', icon: 'contacts' },

        //         {
        //             label: "Vehicle Management", icon: "directions_bus", items: [
        //                 { link: '/vehicles', label: 'Vehicles', icon: 'directions_bus' },
        //                 { link: '/vehicle-scheduling', label: 'Vehicle Scheduling', icon: 'departure_board' },
        //                 { link: '/scheduling-per-day', label: 'Scheduling By Date', icon: 'departure_board' },

                        
        //             ]
        //         },
        //         { link: '/zone', label: 'Zone', icon: 'apartment' },
        //         { link: '/postal', label: 'Postal', icon: 'apartment' },

        //         { link: '/special-zone', label: 'Special Zone', icon: 'apartment' },
        //         { link: '/special-postal', label: 'Special Postal', icon: 'apartment' },
        //         // {
        //         //     label: 'Item 1.2',
        //         //     faIcon: 'fab fa-accessible-icon',
                  
        //         //     items: [
        //         //         {
        //         //             label: 'Item 1.2.1',
        //         //             link: '/item-1-2-1',
        //         //             faIcon: 'fa-allergies' // Font awesome default prefix is fas
        //         //         },
        //         //         {
        //         //             label: 'Item 1.2.2',
        //         //             faIcon: 'fas fa-ambulance',
        //         //             items: [
        //         //                 {
        //         //                     label: 'Item 1.2.2.1',
        //         //                     faIcon: 'fas fa-anchor',  // Still you can specify if you want to
        //         //                     onSelected: function() {
        //         //                         console.log('Item 1.2.2.1');
        //         //                     }
        //         //                 }
        //         //             ]
        //         //         }
        //         //     ]
        //         // }
        //     ]
        // },
        
        // // { link: '/zone', label: 'Zone', icon: 'apartment' },
        // // { link: '/postal', label: 'Postal', icon: 'apartment' },
        // { link: '/alerts', label: 'Alerts', icon: 'message' },
        // { link: '/messages', label: 'Messages', icon: 'message' },
        

        // {
        //     label: "Reports", icon: "library_books", items: [
        //         // { link: '/reports', label: 'Reports by Status', icon: 'library_books' },
        //         // { link: '/reports/driver', label: 'Reports by Driver', icon: 'library_books' },
        //         // { link: '/reports/counts-by-status', label: 'Counts by Status', icon: 'library_books' },

        //         { link: '/reports/actualdelivery-vs-offerdelivery', label: 'Status Report', icon: 'library_books' },
        //         { link: '/reportbusbnc', label: 'Counts', icon: 'library_books' },
        //         { link: '/failed-status-logs', label: 'Failed Status Logs', icon: 'library_books' },
        //         // { link: '/reports/count-actualdelivery-vs-offerdelivery', label: 'Count Actual/Offer dates', icon: 'library_books' },
        //     ]
        // },
       
    ];
    config = {
        paddingAtStart: true,
        interfaceWithRoute: true,
        classname: "ng-star-inserted",
        listBackgroundColor: ``,
        fontColor: `#66615B`,
        backgroundColor: ``,
        selectedListFontColor: `rgb(255, 255, 255)`,
        highlightOnSelect: true,
        collapseOnSelect: true,
        rtlLayout: false,
    };
    public menuItems: any[];
    userInfo = []
    ngOnInit() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.menuItems = ROUTES.filter(menuItem => menuItem);
  
    }
}
