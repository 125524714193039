import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ToastrService } from "ngx-toastr";
export class AuthInfo {
  constructor(public $uid: string) { }

  isLoggedIn() {
    return !!this.$uid;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApisService {
  headers;
  baseUrl: any = '';
  constructor(
    private http: HttpClient,
    private toastr: ToastrService
  ) {
    this.baseUrl = environment.urlAddress;

  }

  updateToken(token='') {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      "token":  token || localStorage.getItem("isLogin"),
    });
  }

  translate(str: any) {
    return str;
  } 

  public postFormData(url: any, body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.baseUrl + url, body).subscribe((data) => {
        resolve(data);
      }, error => {
        resolve(error);
      });
    });
  }

  public get(url: any,id='', token=''): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.updateToken(token)
      console.log(this.baseUrl + url+id)
      this.http.get(this.baseUrl + url+id,  { headers: this.headers }).subscribe((data) => {
        resolve(data);
      }, error => {
        resolve(error);
      });
    });
  }

  public getList(url: any,filter, sortby, sort, skip, page, limit, createdDate=''): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.updateToken()
      this.http.get(this.baseUrl + url+"?orderby=" +
      sort +
      "&sort=" +
      sortby +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&created_date=" +
      createdDate +
      "&skip=" +
      skip +
      "&filter=" +
      JSON.stringify(filter) +
      "&dt=" +
      Math.floor(Math.random() * 90000) +
      10000, { headers: this.headers }).subscribe((data) => {
        resolve(data);
      }, error => {
        resolve(error);
      });
    });
  }

  public postData(url: any, body: any,id =0, token=null): Promise<any> {
    let headerset = {
      headers: new HttpHeaders()
        .set("token", token || localStorage.getItem("isLogin"))
    };
    return new Promise<any>((resolve, reject) => {
      if(id==0){
        this.http.post(this.baseUrl + url, body,headerset).subscribe((data) => {
          resolve(data);
        }, error => {
          resolve(error);
        });
      }else{
        this.updateToken()
        this.http.put(this.baseUrl + url+'/'+id, body,{ headers: this.headers }).subscribe((data) => {
          resolve(data);
        }, error => {
          resolve(error);
        });
      }
      
    });
  }

  public postDataJSON(url: any, body: any,id =0): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.updateToken()
      if(id==0){
        this.http.post(this.baseUrl + url, body,{ headers: this.headers }).subscribe((data) => {
          resolve(data);
        }, error => {
          resolve(error);
        });
      }else{
        this.http.put(this.baseUrl + url+'/'+id, body,{ headers: this.headers }).subscribe((data) => {
          resolve(data);
        }, error => {
          resolve(error);
        });
      }
      
    });
  }

  public pustData(url: any, body: any,id =0): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.updateToken()
      this.http.put(this.baseUrl + url, body,{ headers: this.headers }).subscribe((data) => {
        resolve(data);
      }, error => {
        resolve(error);
      });

    });
  }

}
