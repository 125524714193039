import { Component, OnInit, } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { ApisService } from 'app/services/apis.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from "moment";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-barcodes',
  templateUrl: './barcodes-despatch-date.component.html',
  styleUrls: ['./barcodes-despatch-date.component.css']
})
export class BarcodesDespatchDateComponent implements OnInit {
  params = null;
  orderData = {}
  deliverData = {}
  productData = []
  assistantname = ''

 
  constructor(private router: Router, public api: ApisService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private dateAdapter: DateAdapter<Date>) {
    this.params = this.route.snapshot.params;
    console.log("params", this.params);
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {

    let token = localStorage.getItem('isLogin');
    if(!token){
      this.router.navigate(['/login']);
    }else{

      //execute
      
    }

    this.getOrderDetails();

  }

  getOrderType(sh, odn, cc){
    var orderType = 'Delivery';
    if(isNaN(sh)){

     
      let isAssembly = sh.search("MTA");
      let isDelivery = sh.search("MTD");
      let isCollection = sh.search("MTC");
      if(isAssembly > -1){
        orderType = 'Assembly';
      }else if(isDelivery > -1){
        orderType = 'Delivery';
      }else if(isCollection > -1){
        orderType = 'Collection';
      }else {
        orderType = 'Delivery';
      }
      
      return orderType;
     }else{
      if(odn == '1' || odn == 1){
        orderType = 'Replacement';
        return orderType;
      }else if(cc != '' && cc != null && cc != 'null' && cc != undefined && cc != 'undefined'){
        orderType = 'Collection';
        return orderType;
      }else{
        orderType = 'Collection Only';
        return orderType;
      }
     
     }
 }

  getFormat(date_any){
    return moment(date_any).format("DD-MM-YYYY")
 }

 getArrayFormat(barcodes){

  var g = [];
  var barray =  barcodes.split(",");
  barray.forEach((name, index) => {

    // console.log("name",name);
    g.push({ "num": index+1, "barcode": name, "blength": barray.length });

  });
  // console.log("ggggg",g);
  return g;
 }
 getSorted(ordsArray){
   console.log("ordsArray",ordsArray);
  return ordsArray.sort((a, b) => a.sortno - b.sortno);
}

getOrderDetails() {
  this.spinner.show();
  var dataArray = {};
  dataArray["from"] = moment(this.params.from).format("YYYY-MM-DD");
  dataArray["to"] = moment(this.params.to).format("YYYY-MM-DD");
  dataArray["partner_id"] = JSON.parse(localStorage.getItem('userInfo'))[0].id;

  this.api.postData('secureApi/getOrderBarcodesDespatchDates', dataArray).then((data: any) => {
    this.spinner.hide();
    if (data['results'] ) {
      this.productData = data.results;
      console.log("this.productData",this.productData);

    } else {
      let error = (data['error']['error']['message']) ? data['error']['error']['message'] : 'Some thing gone wrong'
      Swal.fire('Oops...', error, 'error')
    }
  }, error => {
    this.spinner.hide();
    Swal.fire('Oops...', 'Some thing gone wrong!', 'error')
  });

}

// getOrderDetails() {
//     this.api.get('secureApi/getProductsBarcodes/' + this.params.code).then((data: any) => {
//       this.spinner.hide();
//       console.log("data",data);
//       this.productData = data.results;
//       console.log("this.productData",this.productData);
//     }, error => {
//       this.spinner.hide();

//     });
//   }




}
