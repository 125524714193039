import { Injectable, Output, EventEmitter } from "@angular/core";
@Injectable()
export class BtableService {
  setAllColumnIdentical(source) {
    var iterateData = [];
    if (source.column2.length > 0) {
      source.columns.forEach((element) => {
        
        if (element.type == "head") {
          element.column2.forEach((el) => {
            iterateData.push(
              this.getjsonsearch(source.column2, "datafield", el)
            );
          });
        } else {
          iterateData.push(element);
        }
      });
    } else {
      iterateData = source.columns;
    }
    return iterateData;
  }
  getClassName(){
    return 'pop'
  }

  getjsonsearch(obj, key, val) {
    for (var i = 0; i < obj.length; i++) {
      if (obj[i][key] == val) {
        return obj[i];
      }
    }
  }

  // service implementation
  GetPager(totalItems, currentPage, pageSize) {
    if(totalItems==0){
      currentPage=0
    }else{
      currentPage = currentPage || 1;
    } 
    // default to first page
    

    // default page size is 10
    pageSize = pageSize || 5;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= 5) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 2) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 3 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 3;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    var pages = this.getRepeater(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  getRepeater(start, end) {
    var arrdoc = [];
    for (var i = start; i < end; i++) {
      arrdoc.push(i);
    }
    return arrdoc;
  }
}
