import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule} from '@angular/common/http';
import { SidebarModule } from './sidebar/sidebar.module';
import { Sidebar2Module } from './sidebar2/sidebar2.module';
import { Sidebar3Module } from './sidebar3/sidebar3.module';
import { Sidebar4Module } from './sidebar4/sidebar4.module';
import { NavbarModule} from './shared/navbar/navbar.module';

import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { LoginComponent } from './login/login.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BtableService } from "./components/btable/btable.service";
import { AgmCoreModule } from '@agm/core';            // @agm/core
import { AgmDirectionModule } from 'agm-direction';   // agm-direction

import { BrowserModule } from '@angular/platform-browser';
import { NgxBarcodeModule } from 'ngx-barcode';
// import {
//   MatDatepickerModule,
//   MatNativeDateModule,
//   MatFormFieldModule,
//   MatAutocompleteModule,
//   MatChipsModule,
//   MatSelectModule, 
// } from "@angular/material";

import { MatNativeDateModule } from '@angular/material/core';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ScheduleOrderComponent } from './schedule-order/schedule-order.component';
import { TrackComponent } from './track/track.component';
import { PrintComponent } from './print/print.component';
import { PrintcComponent } from './printc/printc.component';
import { PrintCollectionComponent } from './print-collection/print-collection.component';
import { PickingRoundsComponent } from './picking-rounds/picking-rounds.component';
import { BarcodesComponent } from './barcodes/barcodes.component';
import { BarcodesProductComponent } from './barcodes-product/barcodes-product.component';
import { BarcodesDespatchDateComponent } from './barcodes-despatch-date/barcodes-despatch-date.component';
import { PrintLabelsComponent } from './print-labels/print-labels.component';
import { BComponent } from './b/b.component';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    ScheduleOrderComponent,
    TrackComponent,
    PrintComponent,
    PrintcComponent,
    PrintCollectionComponent,
    PickingRoundsComponent,
    BarcodesComponent,
    BarcodesProductComponent,
    BarcodesDespatchDateComponent,
    PrintLabelsComponent,
    BComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    HttpClientModule,
    SidebarModule,
    Sidebar2Module,
    Sidebar3Module,
    Sidebar4Module,
    NavbarModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    FormsModule, ReactiveFormsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatNativeDateModule,
    AgmCoreModule.forRoot({ // @agm/core
      apiKey: 'AIzaSyB7GkBG7v47c0uiaIhED_X-UEnv0jR3-_k',
    }),
    AgmDirectionModule,     // agm-direction
    BrowserModule,
    NgxBarcodeModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [BtableService],
  bootstrap: [AppComponent]
})
export class AppModule { }
