import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { ScheduleOrderComponent } from './schedule-order/schedule-order.component';
import { TrackComponent } from './track/track.component';
import { PrintComponent } from './print/print.component';
import { PrintcComponent } from './printc/printc.component';
import { PrintCollectionComponent } from './print-collection/print-collection.component';
import { PickingRoundsComponent } from './picking-rounds/picking-rounds.component';
import { BarcodesComponent } from './barcodes/barcodes.component';
import { BarcodesProductComponent } from './barcodes-product/barcodes-product.component';
import { BarcodesDespatchDateComponent } from './barcodes-despatch-date/barcodes-despatch-date.component';
import { PrintLabelsComponent } from './print-labels/print-labels.component';
import { BComponent } from './b/b.component';


export const AppRoutes: Routes = [
  { path: 'schedule-order/:code/:token', component: ScheduleOrderComponent, data: { title: 'Schedule Order' } },
  { path: 'track/:code/:token', component: TrackComponent, data: { title: 'Track Order' } },
  { path: 'print/:code', component: PrintComponent, data: { title: 'Print Order' } },
  { path: 'printc/:code', component: PrintcComponent, data: { title: 'Print Order' } },
  { path: 'print-collection/:code/:date', component: PrintCollectionComponent, data: { title: 'Print Collection' } },
  { path: 'picking-rounds/:code/:date', component: PickingRoundsComponent, data: { title: 'Picking rounds' } },
  { path: 'barcodes/:code', component: BarcodesComponent, data: { title: 'Print Barcodes' } },
  { path: 'barcodes-product/:code', component: BarcodesProductComponent, data: { title: 'Print Barcodes' } },
  { path: 'b/:code', component: BComponent, data: { title: 'Please wait...' } },
  { path: 'barcodes-despatch-dates/:from/:to', component: BarcodesDespatchDateComponent, data: { title: 'Print Barcodes' } },
  { path: 'print-labels', component: PrintLabelsComponent, data: { title: 'Print Barcodes' } },
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  // }, 
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  // {
  //   path: '**',
  //   redirectTo: 'login'
  // },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
]
