import { Component, OnInit, } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { ApisService } from 'app/services/apis.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from "moment";
import Swal from 'sweetalert2';

import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.css']
})
export class TrackComponent implements OnInit {
  public lat = null;
  public lng = null;
  params = null;
  public driverLocation: any;
  public customerLocation: any;
  subscription: Subscription;
  intervalId: number;

  public user: FormGroup;
  constructor(private router: Router, public api: ApisService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private dateAdapter: DateAdapter<Date>) {
    this.params = this.route.snapshot.params;
    console.log("params", this.params);
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {

    const source = interval(10000);
    
    this.subscription = source.subscribe(val => this.getTrackingData());
    

  }

  ngOnDestroy() {
    // For method 1
    this.subscription && this.subscription.unsubscribe();

    // For method 2
    clearInterval(this.intervalId);
  }


  getTrackingData() {
    
    console.log("interval", this.params);
    this.api.get('api/v1Auth/vehicles/getTrackingData?code=' + this.params.code+'&token=' + this.params.token, '', this.params.token).then((data: any) => {
    //this.api.postData('secureApi/vehicles/getTrackingData', this.params).then((data: any) => {
              console.log("data", data);
              if(data.length > 0){
              this.driverLocation = { lat: data[0].lat, lng: data[0].lng, name: data[0].vehicle_name};
              //this.lat = data[0].lat;
              //this.lng = data[0].lng;
              this.customerLocation = { lat: data[1].lat, lng: data[1].lng, name: data[1].customer_name, address: data[1].address1};
              }
    });
  }




}
