import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Sidebar3Component } from './sidebar3.component';
import { NgMaterialMultilevelMenuModule, MultilevelMenuService } from 'ng-material-multilevel-menu';


@NgModule({
    imports: [ RouterModule, CommonModule, NgMaterialMultilevelMenuModule ],
    declarations: [ Sidebar3Component ],
    exports: [ Sidebar3Component ],
    providers: [MultilevelMenuService]
})

export class Sidebar3Module {
    
}
