import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Sidebar2Component } from './sidebar2.component';
import { NgMaterialMultilevelMenuModule, MultilevelMenuService } from 'ng-material-multilevel-menu';


@NgModule({
    imports: [ RouterModule, CommonModule, NgMaterialMultilevelMenuModule ],
    declarations: [ Sidebar2Component ],
    exports: [ Sidebar2Component ],
    providers: [MultilevelMenuService]
})

export class Sidebar2Module {
    
}
