import { Component, OnInit } from "@angular/core";
import { Router, Event, NavigationEnd, ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  public lat = 24.799448;
  public lng = 120.979021;

  public origin: any;
  public destination: any;
  params = null;
  constructor(private router: Router, private route: ActivatedRoute) {

  }
  ngOnInit(): void {
    this.params = this.route.snapshot.params
    if ((window.location.href.indexOf('schedule-order') < 0) && (window.location.href.indexOf('track') < 0) && (window.location.href.indexOf('b') < 0) ) {
 
      let token = localStorage.getItem('isLogin');
      if (!token) {
        this.router.navigate(['/login']);
      }else{
        if(JSON.parse(localStorage.getItem('userInfo'))[0].role == 2){
          this.router.navigate(['/order/1/1']);
        }else{
          //page will execute
        }
      }
      this.getDirection();
    } else {
      //localStorage.clear()
    }
  }
  getDirection() {
    this.origin = { lat: 24.799448, lng: 120.979021 };
    this.destination = { lat: 24.799524, lng: 120.975017 };

    // Location within a string
    // this.origin = 'Taipei Main Station';
    // this.destination = 'Taiwan Presidential Office';
  }
}
