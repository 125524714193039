import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  isRoleChanged = 0;
  roleIs = 0;
  userIdCheck = 0;
  ngOnInit() { }

  changeOfRoutes() {
    var isLogin = localStorage.getItem('isLogin');
    if (isLogin) {
      console.log("userInfo", localStorage.getItem('userInfo'));
      if(JSON.parse(localStorage.getItem('userInfo'))[0].role == 2 || JSON.parse(localStorage.getItem('userInfo'))[0].role == 3){
        
        this.isRoleChanged = 1;
        this.roleIs = JSON.parse(localStorage.getItem('userInfo'))[0].role;
        this.userIdCheck = JSON.parse(localStorage.getItem('userInfo'))[0].id;
      }else{
        this.isRoleChanged = 0;
        this.roleIs = JSON.parse(localStorage.getItem('userInfo'))[0].role;
        this.userIdCheck = JSON.parse(localStorage.getItem('userInfo'))[0].id;
      }
      // code here you want to do
    } else {
      alert("Please Login First");
      this.router.navigate(['/login']);
    }
  }


}
