import { Component, OnInit, } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { ApisService } from 'app/services/apis.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from "moment";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-picking-rounds',
  templateUrl: './picking-rounds.component.html',
  styleUrls: ['./picking-rounds.component.css']
})
export class PickingRoundsComponent implements OnInit {
  params = null;
  orderData = {}
  deliverData = {}
  productData = []
  assistantname = ''

 
  constructor(private router: Router, public api: ApisService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private dateAdapter: DateAdapter<Date>) {
    this.params = this.route.snapshot.params;
    console.log("params", this.params);
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {

    this.getOrderDetails();

  }



  getFormat(date_any){
    return moment(date_any).format("DD-MM-YYYY")
 }

 getSorted(ordsArray){
   console.log("ordsArray",ordsArray);
  return ordsArray.sort((a, b) => a.sortno - b.sortno);
}

getOrderType(dtype, sh){
  if(dtype == 'Collection' || dtype == 'collection' || dtype == 'Collection Only' || dtype == 'Collection only'){
    return dtype;
    
      
  
  }else{
    //return 0;
  
        let isAssembly = sh.search("MTA");
        let isDelivery = sh.search("MTD");
        let isCollection = sh.search("MTC");
        if(isAssembly > -1){
          return 'Assembly';
        }else if(isDelivery > -1){
          return 'Delivery';
        }else if(isCollection > -1){
          return 'Collection';
        }else {
          return 'Delivery';
        }
  
  }
}
getitscolor(dtype, sh){
if(dtype == 'Collection' || dtype == 'collection' || dtype == 'Collection Only' || dtype == 'Collection only'){
  return 1;
  
    

}else{
  //return 0;

      let isAssembly = sh.search("MTA");
      let isDelivery = sh.search("MTD");
      let isCollection = sh.search("MTC");
      if(isAssembly > -1){
        return 2;
      }else if(isDelivery > -1){
        return 0;
      }else if(isCollection > -1){
        return 1;
      }else {
        return 0;
      }

}
var orderType = 'Delivery';
let isAssembly = sh.search("MTA");
      let isDelivery = sh.search("MTD");
      let isCollection = sh.search("MTC");
      if(isAssembly > -1){
        orderType = 'Assembly';
      }else if(isDelivery > -1){
        orderType = 'Delivery';
      }else if(isCollection > -1){
        orderType = 'Collection';
      }else {
        orderType = 'Delivery';
      }

}
  getOrderDetails() {
    this.api.get('secureApi/vehicleorders/getPickingRounds/' + this.params.code+'/'+this.params.date).then((data: any) => {
      this.spinner.hide();
      console.log("data",data);
      this.productData = data.results;

      this.assistantname = data.assistantname;

    }, error => {
      this.spinner.hide();

    });
  }




}
