import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
  moduleId: module.id,
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;

  public isCollapsed = true;
  @ViewChild("navbar-cmp", { static: false }) button;
  showChangePassword = 1;
  constructor(
    private titleService: Title,
    location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2, private element: ElementRef) {
    this.setTitle();
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = true;

    console.log("location",location);
    console.log("location",location['_platformLocation']['location']['href']);
    if(location['_platformLocation']['location']['href'] == "http://localhost:4200/#/" || location['_platformLocation']['location']['href'] == "http://localhost:4200" || location['_platformLocation']['location']['href'] == "http://localhost:4200/" || location['_platformLocation']['location']['href'] == "http://localhost:4200/#"){
      window.location.href = "http://localhost:4200/#/login";
    }else if(location['_platformLocation']['location']['href'] == "https://dpod.drsdelivers.co.uk/#/" || location['_platformLocation']['location']['href'] == "https://dpod.drsdelivers.co.uk" || location['_platformLocation']['location']['href'] == "https://dpod.drsdelivers.co.uk/" || location['_platformLocation']['location']['href'] == "https://dpod.drsdelivers.co.uk/#"){

    window.location.href = "https://dpod.drsdelivers.co.uk/#/login";
    }else{
      this.sidebarOpen();
    }
    
  }

  public HeaderTitle = "";
  ngOnInit() {
    if(JSON.parse(localStorage.getItem('userInfo'))[0].role == 1 || JSON.parse(localStorage.getItem('userInfo'))[0].role == 2){
      this.showChangePassword = 0;
    }
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      //this.sidebarClose();
      this.sidebarOpen();
    });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

  setTitle() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(data => {
        this.titleService.setTitle(data.title);
        this.getTitle();
      });
    });
  }

  getTitle() {
    this.HeaderTitle = this.titleService.getTitle();
  }


  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      if(toggleButton == undefined){
        const myArray = window.location.href.split("#");
        // window.location.href
         //window.location.href = myArray[0]+"#/login";
        //this.router.navigate(['/login']);
      }
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };
  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    console.log(navbar);
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  fn_logout_admin() {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      //   Swal.fire(
      //     'Cancelled',
      //     'Your imaginary file is safe :)',
      //     'error'
      //   )
      // }
    })
  }
}
