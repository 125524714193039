import { Component, OnInit, } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { ApisService } from 'app/services/apis.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from "moment";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css']
})
export class PrintComponent implements OnInit {
  params = null;
  orderData = {}
  deliverData = {}
  productData = []


 
  constructor(private router: Router, public api: ApisService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private dateAdapter: DateAdapter<Date>) {
    this.params = this.route.snapshot.params;
    console.log("params", this.params);
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {

    this.getOrderDetails();

  }



  getFormat(date_any){
    return moment(date_any).format("DD-MM-YYYY")
 }

  getOrderDetails() {
    this.api.get('secureApi/fetch/order/' + this.params.code).then((data: any) => {
      this.spinner.hide();

      console.log("data",data);
      this.orderData = data[0][0];
      this.deliverData = data[1][0];
  

      this.productData = data[0].map(item=>{
        return {
          product: item.customer_product_id,
          description: item.product,
          qty: item.qty,
          cartons: item.cartons,
          volume_cu_ft: item.volume_cu_ft,
          weight: item.weight,
        }
      })
      console.log(this.productData)
    }, error => {
      this.spinner.hide();

    });
  }




}
