import { Component, OnInit, } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { ApisService } from 'app/services/apis.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from "moment";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-schedule-order',
  templateUrl: './schedule-order.component.html',
  styleUrls: ['./schedule-order.component.css']
})
export class ScheduleOrderComponent implements OnInit {
  params = null;
  orderData = {}
  productData = []
  offlineData = []
  offlineDataFilter
  public scheduleOrderForm: FormGroup;

  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  date = this.now.getDate();
  newDate = this.now.setDate(this.date + 89)
  minDate = moment({ year: this.year, month: this.month, date: this.date }).format('YYYY-MM-DD');

  maxDate = moment(this.newDate).format('YYYY-MM-DD');

  isLinkExpired = 0;

  isTextAreaEnable = 0;

  order_key = 'Collection';
  constructor(private router: Router, public api: ApisService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
    this.params = this.route.snapshot.params
  }

  ngOnInit(): void {
    this.scheduleOrderForm = new FormGroup({
      order_id: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      customer_name: new FormControl('', [Validators.required]),
      customer_email: new FormControl(''),
      customer_mobile: new FormControl(''),
      sh_number: new FormControl(''),
      first_offered_deliverydate: new FormControl(''),
      customer_mobile2: new FormControl(''),
      customer_order_two: new FormControl(''),
      delivery_option: new FormControl('1'),
      driver_instructions: new FormControl(''),
    });
    this.getOrderDetails()
  }

  onKeypressEvent(event: any){
    event.preventDefault();
    console.log(event);
 
 }

 changeDeliveryOption(){
  if(this.scheduleOrderForm.get("delivery_option").value == 3){
    this.isTextAreaEnable = 1
  }else{
    this.isTextAreaEnable = 0
  }
 }

  updateScheduleDate(value) {
    console.log("value",value.date);
if(value.date == "" || value.date == undefined || value.date == null){
  Swal.fire('Oops...', 'Please select a date.', 'error')
  return;
}else{
  let resultType = Number.isInteger(parseInt(value.sh_number));
  if (resultType == true) {

    Swal.fire({
      title: 'Do you want to submit?',
      //html: '<p style="text-align: left;">By clicking the submit button, you confirm that</p> <p style="text-align: left;"><ul style="text-align: left;"><li>The product/s you have ordered, delivery address, post-code and phone number are correct.</li></ul></p> <p style="text-align: left;"><ul style="text-align: left;"><li>Access for delivery of the item is adequate.</li></ul></p> <p style="text-align: left;"><ul style="text-align: left;"><li>You have read, understood and agree with the terms and conditions of our Covid and Delivery Policy <a target="_blank" href="https://drsdelivers.co.uk/delivery-and-covid-policy">https://drsdelivers.co.uk/delivery-and-covid-policy</a></li></ul></p><p style="text-align: left;"><span style="color:red">Any issues?</span> Please contact bookings@drsdelivers.co.uk OR call us on 01494 936451</p>',
      showCancelButton: true,
      confirmButtonText: `Submit`,

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        
        console.log(value)
        let sendData = value;
        sendData.date = moment(sendData.date).format("YYYY-MM-DD")
        sendData.order_key = this.order_key;
        console.log("sendData", sendData)
        // return false;
        this.api.postData('secureApi/schedule/order', sendData, 0, this.params.token).then((data: any) => {
          this.spinner.hide();
          if (data['success'] == false) {
            Swal.fire('Oops...', data.message, 'error')
            //this.router.navigate(['/login']);
            return;
          }
          Swal.fire('Hooray!', data.message, 'success')
        });

      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  } else {

    if(this.order_key == 'Delivery'){
      // alert(value.delivery_option);
      // alert(value.driver_instructions);
      if((value.delivery_option == '3' || value.order_key == 3) && value.driver_instructions ==""){
        Swal.fire('Oops...', 'Please write something in the text box.', 'error')
        return;
      }
      
      Swal.fire({
        title: 'Do you want to submit?',
        html: '<p style="text-align: left;">By clicking the submit button, you confirm that</p> <p style="text-align: left;"><ul style="text-align: left;"><li>The product/s you have ordered, delivery address, post-code and phone number are correct.</li></ul></p> <p style="text-align: left;"><ul style="text-align: left;"><li>Access for delivery of the item is adequate.</li></ul></p> <p style="text-align: left;"><ul style="text-align: left;"><li>You have read, understood and agree with the terms and conditions of our Covid and Delivery Policy <a target="_blank" href="https://drsdelivers.co.uk/delivery-and-covid-policy">https://drsdelivers.co.uk/delivery-and-covid-policy</a></li></ul></p><p style="text-align: left;"><span style="color:red">Any issues?</span> Please contact bookings@drsdelivers.co.uk OR call us on 01494 936451</p>',
        showCancelButton: true,
        confirmButtonText: `Submit`,
  
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          
          // console.log(value)
          let sendData = value;
          sendData.date = moment(sendData.date).format("YYYY-MM-DD")
          sendData.order_key = this.order_key;
          console.log("sendData", sendData)
          // return false;
  
          this.api.postData('secureApi/schedule/order', sendData, 0, this.params.token).then((data: any) => {
            this.spinner.hide();
            if (data['success'] == false) {
              Swal.fire('Oops...', data.message, 'error')
              //this.router.navigate(['/login']);
              return;
            }
            Swal.fire('Hooray!', data.message, 'success')
          });
  
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })

    }else{

      Swal.fire({
        title: 'Do you want to submit?',
        //html: '<p style="text-align: left;">By clicking the submit button, you confirm that</p> <p style="text-align: left;"><ul style="text-align: left;"><li>The product/s you have ordered, delivery address, post-code and phone number are correct.</li></ul></p> <p style="text-align: left;"><ul style="text-align: left;"><li>Access for delivery of the item is adequate.</li></ul></p> <p style="text-align: left;"><ul style="text-align: left;"><li>You have read, understood and agree with the terms and conditions of our Covid and Delivery Policy <a target="_blank" href="https://drsdelivers.co.uk/delivery-and-covid-policy">https://drsdelivers.co.uk/delivery-and-covid-policy</a></li></ul></p><p style="text-align: left;"><span style="color:red">Any issues?</span> Please contact bookings@drsdelivers.co.uk OR call us on 01494 936451</p>',
        showCancelButton: true,
        confirmButtonText: `Submit`,
  
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          
          console.log(value)
          let sendData = value;
          sendData.date = moment(sendData.date).format("YYYY-MM-DD")
          sendData.order_key = this.order_key;
          console.log("sendData", sendData)
          // return false;
          this.api.postData('secureApi/schedule/order', sendData, 0, this.params.token).then((data: any) => {
            this.spinner.hide();
            if (data['success'] == false) {
              Swal.fire('Oops...', data.message, 'error')
              //this.router.navigate(['/login']);
              return;
            }
            Swal.fire('Hooray!', data.message, 'success')
          });
  
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })

    }

    
  }
}

    
 
    
    


    
    
  }

  change(event) {

  }

  getOrderDetails() {
    this.api.get('secureApi/schedule/order?code=' + this.params.code, '', this.params.token).then((data: any) => {
      this.spinner.hide()
      console.log(data)

      if(data == null){
        this.isLinkExpired = 1;
      }


      if (data['success'] == false) {
        localStorage.clear();
        Swal.fire('Oops...', data.message, 'error')
        this.router.navigate(['/login']);
        return
      }
      this.orderData = data.results[0];
      
      this.offlineData = data.events.map(item => {
        return new Date(item.date)
      });
      console.log(this.offlineData)

      this.offlineDataFilter = (d: Date): boolean => {
        if(d){
          const time = d.getTime();
          return !this.offlineData.find(x => x.getTime() == time);
        }
        return false
      }

      this.scheduleOrderForm.get("order_id").setValue(data.results[0].id);
      this.scheduleOrderForm.get("customer_name").setValue(data.results[0].customer_name);
      this.scheduleOrderForm.get("customer_email").setValue(data.results[0].email);
      this.scheduleOrderForm.get("customer_mobile").setValue(data.results[0].telephone1);

      this.scheduleOrderForm.get("customer_mobile2").setValue(data.results[0].mobile);

      
      this.scheduleOrderForm.get("code").setValue(this.params.code);

      console.log("sh_number",this.orderData["sh_number"]);

      this.scheduleOrderForm.get("sh_number").setValue(data.results[0].sh_number);


      this.scheduleOrderForm.get("first_offered_deliverydate").setValue(data.first_offered_deliverydate);

      this.scheduleOrderForm.get("customer_order_two").setValue(data.results[0].customer_order_two);
      

      if(Number.isInteger(parseInt(data.results[0].sh_number)) == true){
        this.order_key = 'Collection';
      }else{
        if(data.results[0].order_key == 'null' || data.results[0].order_key == null || data.results[0].order_key == '' || data.results[0].order_key == 'Delivery'){
          this.order_key = 'Delivery';
        }else{
          this.order_key = 'Collection';
        }
      }

     
      
      
      // this.scheduleOrderForm.get("date").setValue(moment(data.results[0].scheduled_date).format("YYYY-MM-DD"));
      this.productData = data.results.map(item => {
        return {
          product: item.product,
          description: item.description,
          qty: item.qty,
          cartons: item.cartons,
          volume_cu_ft: item.volume_cu_ft,
          customer_product_id: item.customer_product_id
        }
      })
      console.log(this.productData)
    }, error => {
      this.spinner.hide();

    });
  }

}
